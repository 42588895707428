import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { AboutPageQuery } from '../data';
import { Layout, SEO, PageHero, GraphQLBlock } from '../blocks';
import { Card } from '../components';

const graphicSvg = require('../assets/icons/icon-about-graphic.svg');
const lighthouseIcon = require('../assets/icons/icon-lighthouse.svg');
const stacksSvg = require('../assets/icons/icon-stacks@2x.png');

const About: React.SFC = () => (
  <StaticQuery
    query={ABOUT_QUERY}
    render={(data: AboutPageQuery) => (
      <Layout>
        <SEO title={`NuWave Commerce | About Us`} />
        <PageHero
          title={data.markdownRemark.frontmatter.tagline}
          subTitle={data.markdownRemark.frontmatter.subTagline}
        />
        <section className="bg-white">
          <div className="container mx-auto py-12">
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 px-4"
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
              <div className="w-full mt-8 md:mt-0 md:flex md:w-1/2 px-4 items-center">
                <div className="mx-auto" style={{ maxWidth: 450 }}>
                  <img src={graphicSvg} alt="About NuWave Commerce Graphic" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container mx-auto py-12">
            <div className="flex flex-wrap">
              <div className="hidden w-full md:w-1/2 px-4 md:flex justify-center items-center">
                <img
                  src={lighthouseIcon}
                  alt="Lighthouse (Laravel GraphQL) icon"
                  style={{ maxWidth: 200 }}
                />
              </div>
              <div className="w-full md:w-1/2 px-4">
                <h2>Open Source Development</h2>
                <p>
                  We are committed to giving back to the Laravel Community.
                  Because of our unique skills and technical background we open
                  sourced our GraphQL package several years ago. It has been
                  widely adopted by developers in the Laravel community and is
                  setting a new standard in GraphQL development in the PHP
                  community.
                </p>
                <p>
                  <a
                    href="https://lighthouse-php.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-serif font-bold uppercase text-sm text-purple-dark hover:text-purple-darkest"
                  >
                    Explore Documentation
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white pt-8 pb-2 md:pt-12 md:pb-12">
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              {data.markdownRemark.frontmatter.cards.map(
                ({ __typename, ...card }) => (
                  <div
                    key={card.icon}
                    className="w-full pb-8 md:pb-0 md:w-1/2 px-4"
                  >
                    <Card {...card} />
                  </div>
                )
              )}
            </div>
          </div>
        </section>
        <section className="bg-white py-12">
          <div className="container mx-auto">
            <div className="flex flex-wrap-reverse">
              <div className="w-full md:w-1/2 px-4 flex items-center">
                <div className="mx-auto" style={{ maxWidth: 300 }}>
                  <img
                    src={stacksSvg}
                    style={{ maxWidth: '100%' }}
                    alt="Technology Stack Icon"
                  />
                </div>
              </div>
              <div
                className="w-full md:w-1/2 px-4"
                dangerouslySetInnerHTML={{ __html: data.block.html }}
              />
            </div>
          </div>
        </section>
        <GraphQLBlock />
      </Layout>
    )}
  />
);

const ABOUT_QUERY = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { path: { eq: "/about" } }) {
      id
      html
      frontmatter {
        title
        tagline
        subTagline
        path
        cards {
          icon
          color
          title
          content
        }
      }
    }
    block: markdownRemark(
      frontmatter: { slug: { eq: "about" }, type: { eq: "block" } }
    ) {
      id
      html
    }
  }
`;

export default About;
